import React, { Component } from 'react';
import zafClient from '../api/zafClient';
import firebase from '../api/firebase';
import { getChatParams } from '../utils/objectparams';
import { triggerToFrame } from '../utils/frames';

class SidebarChat extends Component {
    async componentDidMount() {
        try {
            // the chat API does not allow pulling out all user obj properties with zafClient.get('currentUser')
            // returns the error: No Zendesk ID found
            const userObj = await zafClient.get(['currentUser.id', 'currentUser.email', 'currentUser.name']);
            const { chat, visitor } = await zafClient.get(['chat', 'visitor']);
            chat.visitor = visitor;
            chat.chatNavTime = new Date().getTime();

            console.log('chat', chat);

            triggerToFrame('background', 'hl_user_interaction', 'load');

            // save the ticket to session storage (useful if cycling between browser tabs)
            sessionStorage.setItem('chat', JSON.stringify(chat));

            await firebase.database().ref(`agents/${userObj['currentUser.id']}`).update({
                ticket: {},
                chat: getChatParams(chat),
                status: 'in-chat',
                statusTime: firebase.database.ServerValue.TIMESTAMP,
                statusAlertTime: null,
                navTime: firebase.database.ServerValue.TIMESTAMP
            });
            // clear out the chat when connection is closed
            firebase.database().ref(`agents/${userObj['currentUser.id']}`).onDisconnect().update({
                chat: {},
                ticket: {},
                status: 'ready',
                statusTime: firebase.database.ServerValue.TIMESTAMP,
                statusAlertTime: null,
                navTime: firebase.database.ServerValue.TIMESTAMP
            });
            zafClient.on('app.activated', async v => {
                sessionStorage.setItem('chat', JSON.stringify(chat));
                await firebase.database().ref(`agents/${userObj['currentUser.id']}`).update({
                    ticket: {},
                    chat: getChatParams(chat),
                    status: 'in-chat',
                    statusTime: firebase.database.ServerValue.TIMESTAMP,
                    statusAlertTime: null,
                    navTime: firebase.database.ServerValue.TIMESTAMP
                });
            });
            zafClient.on('app.deactivated', async v => {
                sessionStorage.removeItem('chat');
                triggerToFrame('background', 'hl_user_interaction', 'unload');
                triggerToFrame('background', 'hl_set_status');
            });
            ['channel.chat.start', 'channel.chat.end', 'channel.department.changed',
                'channel.message.sent', 'channel.textarea.changed', 'channel.ticket.created',
                'channel.window.infocus', 'visitor.display_name.changed', 'visitor.email.changed',
                'visitor.phone.changed', 'visitor.notes.changed'].forEach(v => {
                    zafClient.on(v, async e => {
                        triggerToFrame('background', 'hl_user_interaction', 'chat');
                    });
                });
            // TODO - add channel.ticket.created listener
        } catch (e) {
            console.log('Error in sidebar page', e);
        }
    }
    render() {
        // this is a hidden sidebar used to get chat context
        return (
            <div>
            </div>
        );
    }
}

export default SidebarChat;