import React, { Component } from 'react';
import styled from 'styled-components';
//import Loading from './../Loading';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Notification, Title } from '@zendeskgarden/react-notifications';
import {
    Chrome,
    Body,
    Content,
    Main
} from '@zendeskgarden/react-chrome';
import { zdSpacingLg } from '@zendeskgarden/css-variables';

import firebase from '../../api/firebase';

const PaddedMain = styled(Main)`
  padding: ${zdSpacingLg};
`;

class PermissionGrant extends Component {
    state = {
        token: null,
        error: false,
        loaded: false,
        inZendesk: true,
    }
    async componentDidMount() {
        try {
            const messaging = firebase.messaging();
            await messaging.requestPermission();
            const token = await messaging.getToken();
            //await messaging.deleteToken(token);
            this.setState({
                token,
                error: false,
                loaded: true
            });
            // grab the zendesk user details and add token
            if (token && localStorage.getItem('user')) {
                const currentUser = JSON.parse(localStorage.getItem('user'));
                const database = firebase.database();
                if (currentUser && currentUser.id) {
                    await database.ref(`agents/${currentUser.id}/tokens`).update({
                        [token]: firebase.database.ServerValue.TIMESTAMP
                    });
                }
                firebase.analytics().logEvent('token_granted');
            }
        } catch (e) {
            console.log(e);
            this.setState({
                token: null,
                error: true,
                loaded: true
            });
        }
    }
    render() {
        /*
        if (!this.state.loaded) {
            return <Loading />;
        }
        */
        return (
            <Chrome>
                <Body>
                    <Content>
                        <PaddedMain>
                            <Grid>
                                <Row alignItems="center" style={{ minHeight: '70vh' }}>
                                    <Col md={4}></Col>
                                    <Col md={4}>
                                        {this.state.token ?
                                            <Notification type="success">
                                                <Title>Success!</Title>
                                                You may close this window.
                                            </Notification> : (!this.state.token && !this.state.error) ?
                                                <Notification type="info">
                                                    <Title>Permission Required</Title>
                                                Please click the option to "Allow" notifications.
                                            </Notification> : !this.state.token && this.state.error ?
                                                    <Notification type="error">
                                                        <Title>Error</Title>
                                                There was an error requesting access. Click the "i" icon in the address bar and change your Notifications settings.
                                            </Notification> : <Notification type="error">
                                                        <Title>Error</Title>
                                                Unknown error. Please reload and try again.
                                            </Notification>
                                        }
                                    </Col>
                                    <Col md={4}></Col>
                                </Row>
                            </Grid>
                        </PaddedMain>
                    </Content>
                </Body>
            </Chrome>
        );
    }
}

export default PermissionGrant;