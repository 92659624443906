import React, { Component } from "react";
import styled from "styled-components";
import { Tiles } from "@zendeskgarden/react-forms";
import { Grid, Row, Col } from "@zendeskgarden/react-grid";
import { Notification, Title } from "@zendeskgarden/react-notifications";
import Loading from "./Loading";
import { getStatus } from "../utils/status";
import { triggerToFrame } from "../utils/frames";

import zafClient from "../api/zafClient";

const SmallTile = styled(Tiles.Tile)`
  padding: 12px;
`;

class TopbarSupport extends Component {
  ref = null;
  agent = {};
  state = {
    loaded: false,
    status: null,
    toolbarStatus: null,
  };
  async setStatus(status) {
    try {
      triggerToFrame("background", "hl_set_status", status);
      zafClient.invoke("popover", "hide");
    } catch (e) {
      console.error(e);
    }
  }
  async componentDidMount() {
    try {
      zafClient.on("hl_agent_update", (v) => {
        this.agent = v;
        zafClient.set("iconSymbol", getStatus(v.status).iconSymbol);
        this.setState({
          loaded: true,
          status: v.status,
          toolbarStatus: getStatus(v.status).toolbarStatus,
        });
      });
      triggerToFrame("background", "hl_get_agent", "top_bar");
    } catch (e) {
      console.log("Error in top bar page", e);
    }
  }
  render() {
    if (!this.state.loaded) {
      return <Loading />;
    }
    if (this.state.status === "in-chat") {
      return (
        <Notification type="warning" style={{ minHeight: "100vh" }}>
          <Title>In Chat</Title>
          You are currently in a chat. You must end all chat sessions before
          changing status.
        </Notification>
      );
    } else if (this.state.status === "in-ticket") {
      return (
        <Notification type="warning" style={{ minHeight: "100vh" }}>
          <Title>In Ticket</Title>
          You are currently in a ticket. Close all tickets before changing
          status.
        </Notification>
      );
    } else if (this.state.status === "on-call") {
      return (
        <Notification type="warning" style={{ minHeight: "100vh" }}>
          <Title>On Call</Title>
          You are currently on a call. End your call before changing status.
        </Notification>
      );
    } else if (this.state.status === "wrap-up") {
      return (
        <Notification type="warning" style={{ minHeight: "100vh" }}>
          <Title>Wrap Up</Title>
          You are wrapping up a call. You can change your status after wrapping
          up.
        </Notification>
      );
    }
    return (
      <Tiles
        onChange={(event) => this.setStatus(event.target.value)}
        name="status"
        style={{ height: "100vh" }}
      >
        <Grid style={{ paddingRight: 3, paddingLeft: 3 }}>
          <Row alignItems="center" style={{ height: "12.5vh" }}>
            <Col>
              <SmallTile
                value="ready"
                isSelected={this.state.toolbarStatus === "ready"}
              >
                <Tiles.Label>Ready</Tiles.Label>
              </SmallTile>
            </Col>
          </Row>
          <Row alignItems="center" style={{ height: "12.5vh" }}>
            <Col>
              <SmallTile
                value="be-right-back"
                isSelected={this.state.toolbarStatus === "be-right-back"}
              >
                <Tiles.Label>Be Right Back</Tiles.Label>
              </SmallTile>
            </Col>
          </Row>
          <Row alignItems="center" style={{ height: "12.5vh" }}>
            <Col>
              <SmallTile
                value="social"
                isSelected={this.state.toolbarStatus === "social"}
              >
                <Tiles.Label>Social</Tiles.Label>
              </SmallTile>
            </Col>
          </Row>
          <Row alignItems="center" style={{ height: "12.5vh" }}>
            <Col>
              <SmallTile
                value="break"
                isSelected={this.state.toolbarStatus === "break"}
              >
                <Tiles.Label>Break</Tiles.Label>
              </SmallTile>
            </Col>
          </Row>
          <Row alignItems="center" style={{ height: "12.5vh" }}>
            <Col>
              <SmallTile
                value="lunch"
                isSelected={this.state.toolbarStatus === "lunch"}
              >
                <Tiles.Label>Lunch</Tiles.Label>
              </SmallTile>
            </Col>
          </Row>
          <Row alignItems="center" style={{ height: "12.5vh" }}>
            <Col>
              <SmallTile
                value="meeting"
                isSelected={this.state.toolbarStatus === "meeting"}
              >
                <Tiles.Label>Meeting</Tiles.Label>
              </SmallTile>
            </Col>
          </Row>
          <Row alignItems="center" style={{ height: "12.5vh" }}>
            <Col>
              <SmallTile
                value="training"
                isSelected={this.state.toolbarStatus === "training"}
              >
                <Tiles.Label>Training</Tiles.Label>
              </SmallTile>
            </Col>
          </Row>
          <Row alignItems="center" style={{ height: "12.5vh" }}>
            <Col>
              <SmallTile
                value="busy"
                isSelected={this.state.toolbarStatus === "busy"}
              >
                <Tiles.Label>Busy</Tiles.Label>
              </SmallTile>
            </Col>
          </Row>
        </Grid>
      </Tiles>
    );
  }
}

export default TopbarSupport;
