import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
    Chrome,
    Body,
    Header,
    HeaderItem,
    HeaderItemIcon,
    HeaderItemText,
    Content,
    Main
} from '@zendeskgarden/react-chrome';
import {
    zdSpacing,
    zdSpacingLg
} from '@zendeskgarden/css-variables';
import { XXL } from '@zendeskgarden/react-typography';
//import { ReactComponent as PersonIcon } from '@zendeskgarden/svg-icons/src/16/user-solo-fill.svg';
import { ReactComponent as PeopleIcon } from '@zendeskgarden/svg-icons/src/16/user-group-fill.svg';
import { ReactComponent as PhoneIcon } from '@zendeskgarden/svg-icons/src/16/phone-fill.svg';
import { ReactComponent as GearIcon } from '@zendeskgarden/svg-icons/src/16/gear-fill.svg';

const PaddedMain = styled(Main)`
  padding: ${zdSpacingLg};
`;

const Headline = styled(XXL)`
  margin-bottom: ${zdSpacing};
`;

class Layout extends Component {
    render() {
        return (
            <Chrome hue="#EEE">
                <Body>
                    {this.props.showHeader &&
                        <Header>
                            <HeaderItem isRound onClick={() => this.props.history.push('/dashboard')}>
                                <HeaderItemIcon>
                                    <PeopleIcon />
                                </HeaderItemIcon>
                                <HeaderItemText isClipped>Dashboard</HeaderItemText>
                            </HeaderItem>
                            <HeaderItem isRound onClick={() => this.props.history.push('/dashboard/phone')}>
                                <HeaderItemIcon>
                                    <PhoneIcon />
                                </HeaderItemIcon>
                                <HeaderItemText isClipped>Phone</HeaderItemText>
                            </HeaderItem>
                            <HeaderItem isRound onClick={() => this.props.history.push('/settings')}>
                                <HeaderItemIcon>
                                    <GearIcon />
                                </HeaderItemIcon>
                                <HeaderItemText isClipped>Settings</HeaderItemText>
                            </HeaderItem>
                        </Header>
                    }
                    <Content>
                        <PaddedMain style={{ paddingTop: this.props.noTopPadding ? 0 : zdSpacingLg }}>
                            {this.props.headline &&
                                <Headline tag="h1">{this.props.headline}</Headline>
                            }
                            {this.props.children}
                        </PaddedMain>
                    </Content>
                </Body>
            </Chrome>
        );
    }
}

export default withRouter(Layout);