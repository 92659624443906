// The FirebaseUI client is imported within the index.html file.

let client;

if (typeof firebaseui === 'undefined') {
    throw new Error("FirebaseUI client not found");
} else {
    client = window.firebaseui;
}

export default client;