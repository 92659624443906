import { DateTime } from "luxon";
import { sleep } from "./sleep";
import zafClient from "../api/zafClient";

export async function updateDueDateHook(result) {
  if (
    !result ||
    !result.ticket ||
    !result.ticket.id ||
    result.ticket.type !== "task" ||
    result.ticket.status !== "pending"
  ) {
    return;
  }
  try {
    const { ticket } = await zafClient.request(
      `/api/v2/tickets/${result.ticket.id}.json`
    );
    const followUpDate = ticket.custom_fields.find((v) => v.id === 360031517732)
      .value;
    const followUpTime = ticket.custom_fields.find((v) => v.id === 360031550171)
      .value;
    const iso = DateTime.fromFormat(
      `${followUpDate} ${followUpTime.replace("_", ":")}`,
      "yyyy-MM-dd h:mma",
      { zone: "America/Los_Angeles" }
    )
      .toUTC()
      .toISO();
    const isoAdjusted =
      iso.indexOf("T00:00:00.000Z") > -1
        ? DateTime.fromFormat(
            `${followUpDate} ${followUpTime.replace("_", ":")}`,
            "yyyy-MM-dd h:mma",
            { zone: "America/Los_Angeles" }
          )
            .minus({ minutes: 1 })
            .toUTC()
            .toISO()
        : iso;
    console.log("ready to update to iso", isoAdjusted);
    // wait a bit to prevent ticket collision warning: https://support.zendesk.com/hc/en-us/articles/234795647-Ticket-not-saved-A-change-was-made-to-this-ticket-as-your-update-was-being-saved
    await sleep(2000);
    await zafClient.request({
      url: `/api/v2/tickets/${result.ticket.id}.json`,
      contentType: "application/json",
      method: "PUT",
      dataType: "json",
      data: JSON.stringify({
        ticket: {
          due_at: isoAdjusted,
          safe_update: "false",
        },
      }),
    });
  } catch (e) {
    console.error("Error on ticket update!", e.message);
  }
}
