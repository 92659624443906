import React, { Component } from 'react';
import zafClient from '../api/zafClient';
import firebase from '../api/firebase';

class SidebarChat extends Component {
    async componentDidMount() {
        try {
            const { user } = await zafClient.get('user');
            const database = firebase.database();
            zafClient.on('user.groups.changed', async v => {
                console.log('user groups changed', user.id, v);
                await database.ref(`agents/${user.id}`).update({
                    groups: v.map(g => g.id)
                });
            });
        } catch (e) {
            console.log('Error in sidebar page', e);
        }
    }
    render() {
        // this is a hidden sidebar used to get user context
        return (
            <div>
            </div>
        );
    }
}

export default SidebarChat;