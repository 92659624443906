import React, { Component } from "react";
import zafClient from "../api/zafClient";
import firebase from "../api/firebase";
import { throttle } from "lodash";
import { getTicketParams } from "../utils/objectparams";
import { getStatus, checkTicketStatus } from "../utils/status";
import { getFrame, invokeToFrame, triggerToFrame } from "../utils/frames";
import { updateDueDateHook } from "../utils/duedate";

class BackgroundSupport extends Component {
  maxIdleTime = 5 * 60 * 1000; // ms
  idleTimerActive = false;
  idleTimer = null;
  isIdle = false;
  agentRef = null;
  heartbeatRef = null;
  agent = {};
  ticket = {};
  currentUser = {};
  checkPhone = false;
  lastStatus = "ready";
  warnOffline = throttle(() => {
    zafClient.invoke(
      "notify",
      "Warning! You are in an offline status.",
      "alert",
      4000
    );
  }, 5000);
  enableIdleTimer(active) {
    this.idleTimerActive = active;
    if (active) {
      this.resetIdleTimer();
    } else {
      this.isIdle = false;
      clearTimeout(this.idleTimer);
    }
  }
  async resetIdleTimer() {
    // return to ready state
    if (this.isIdle || this.agent?.status === "idle") {
      this.setStatus("ready");
    }
    if (!this.idleTimerActive) {
      if (
        this.agent?.status !== "on-call" &&
        this.agent?.status !== "wrap-up"
      ) {
        this.warnOffline();
      }
      return;
    }
    this.isIdle = false;
    clearTimeout(this.idleTimer);
    this.idleTimer = setTimeout(this.setIdle.bind(this), this.maxIdleTime);
  }
  async setIdle() {
    this.isIdle = true;
    this.setStatus("idle");
  }
  isPhoneUser() {
    return !!this.currentUser.groups.find((v) => v.name === "Phone Support");
  }
  isManagerUser() {
    return !!this.currentUser.groups.find((v) => v.name === "Managers");
  }
  resetStatus(force) {
    if (
      !force &&
      this.checkPhone &&
      (this.agent?.talk?.status === "on_call" ||
        this.agent?.talk?.status === "wrap_up")
    ) {
      return;
    }
    // set the last status, but do not update the phone
    this.setStatus(this.lastStatus);
  }
  async setStatus(status, setPhone, force) {
    try {
      ({ currentUser: this.currentUser } = await zafClient.get("currentUser"));
      const oldStatus = this.agent.status;
      const timeInOldStatus = this.agent.statusTime
        ? new Date().getTime() - this.agent.statusTime
        : null;
      this.enableIdleTimer(getStatus(status).idleTimer);
      // make sure this is above the potential reassignment to in-ticket!
      if (getStatus(status).saveLastStatus) {
        this.lastStatus = status;
      }
      //this.ticket = JSON.parse(sessionStorage.getItem('ticket'));
      status = checkTicketStatus(status, this.ticket);
      // automatically change the phone status if user is member of the phone support group
      if (
        setPhone &&
        getStatus(status).phoneState &&
        this.agent?.talk?.agent_state !== getStatus(status).phoneState &&
        this.isPhoneUser()
      ) {
        const updateTalkAvailability = firebase
          .functions()
          .httpsCallable("updateTalkAvailability");
        updateTalkAvailability({
          id: this.currentUser.id,
          agent_state: getStatus(status).phoneState,
        }).then(
          (result) => {
            zafClient.invoke(
              "notify",
              `Phone status set to ${getStatus(status).phoneState}.`,
              "notice",
              4000
            );
            console.log(
              "set phone state",
              getStatus(status).phoneState,
              result
            );
          },
          (err) => {
            console.error(
              "error setting phone state",
              getStatus(status).phoneState,
              err
            );
          }
        );
      }
      if (status === oldStatus && !force) {
        return;
      }
      firebase.database().ref(`agents/${this.currentUser.id}`).update({
        status: status,
        statusTime: firebase.database.ServerValue.TIMESTAMP,
        statusAlertTime: null,
      });
      firebase.analytics().logEvent("change_status", {
        new_status: status,
        old_status: oldStatus,
        old_status_time: timeInOldStatus,
      });
    } catch (e) {
      console.error(e);
    }
  }
  setTicket(ticket) {
    this.ticket = ticket;
    if (this.agent.status === "ready" || this.agent.status === "idle") {
      this.setStatus(checkTicketStatus(this.agent.status, ticket));
    }
  }
  async checkTickets() {
    const sidebarFrames = await getFrame([
      "ticket_sidebar",
      "new_ticket_sidebar",
    ]);
    if (!sidebarFrames) {
      this.ticket = {};
      sessionStorage.removeItem("ticket");
      if (document.visibilityState === "visible") {
        this.resetStatus();
        firebase.database().ref(`agents/${this.currentUser.id}`).update({
          ticket: {},
        });
      }
    }
  }
  resetTicket() {
    this.ticket = {};
    sessionStorage.removeItem("ticket");
    this.resetStatus();
  }
  setRefs() {
    if (this.agentRef) {
      this.agentRef.off();
    }
    if (this.heartbeatRef) {
      this.heartbeatRef.off();
    }
    this.heartbeatRef = firebase
      .database()
      .ref("heartbeat/voice_availability/active");
    this.heartbeatRef.on("value", (snap) => {
      this.checkPhone = snap.val();
    });
    this.agentRef = firebase.database().ref(`agents/${this.currentUser.id}`);
    this.agentRef.on("value", async (snap) => {
      const v = snap.val();
      if (!v) {
        return;
      }
      // if user closed another tab, reset to this tab's most recent status instead of offline
      if (v.status === "offline") {
        console.log("got offline, reset to", this.agent.status);
        if (this.agent.status && this.agent.status !== "offline") {
          await this.setStatus(this.agent.status, false, true);
        } else if (this.isManagerUser()) {
          await this.setStatus("busy", false, true);
        } else {
          await this.setStatus("ready", true, true);
        }
        await firebase
          .database()
          .ref(`agents/${this.currentUser.id}`)
          .update({
            zendeskSupportTime: this.agent.zendeskSupportTime
              ? this.agent.zendeskSupportTime
              : firebase.database.ServerValue.TIMESTAMP,
          });
        return;
      }
      if (
        this.checkPhone &&
        v.talk?.status &&
        this.agent?.talk?.status &&
        v.talk.status !== this.agent?.talk?.status
      ) {
        console.log("checking v.talk.status", v.talk.status);
        switch (v.talk.status) {
          case "on_call":
            this.setStatus("on-call");
            break;
          case "wrap_up":
            this.setStatus("wrap-up");
            break;
          case "not_available":
          case "available":
            this.resetStatus(true);
            break;
          default:
            break;
        }
      }
      if (v.status && this.agent.status !== v.status) {
        // set/unset the idle timer if the status changed
        if (getStatus(v.status).idleTimer) {
          this.enableIdleTimer(true);
        } else {
          this.enableIdleTimer(false);
        }
        triggerToFrame("top_bar", "hl_agent_update", v);
      }
      // if the navTime changed, agent might have closed a ticket tab and need to reset status
      if (v.navTime !== this.agent.navTime) {
        this.checkTickets();
      }
      this.agent = v;
    });
  }
  setListeners() {
    // update the user's state when zendesk window is hidden
    document.addEventListener("visibilitychange", async (e) => {
      if (e.srcElement.visibilityState !== "visible") {
        return;
      }
      if (
        (this.agent?.status === "ready" || this.agent?.status === "idle") &&
        (this.ticket?.id || this.ticket?.isNew)
      ) {
        this.setStatus(checkTicketStatus(this.agent.status, this.ticket));
      }
      this.resetIdleTimer();
      await firebase
        .database()
        .ref(`agents/${this.currentUser.id}`)
        .update({
          ticket: getTicketParams(this.ticket),
          chat: {},
          navTime: firebase.database.ServerValue.TIMESTAMP,
        });
    });
    /*
        // listen for changes to sessionStorage ticket, reset/wipe ticket with no tickets open
        window.addEventListener('storage', async e => {
            if (e.key === 'ticket' && !e.newValue) {
                this.checkTickets();
            }
        });
        */
    zafClient.on("ticket.saved", async (v) => {
      // TODO - check if this fires when tabbed but not active!
      this.resetIdleTimer();
      this.setTicket(v);
      console.log("ticket.saved", v);
      await firebase
        .database()
        .ref(`agents/${this.currentUser.id}`)
        .update({
          ticket: getTicketParams(v),
          chat: {},
          navTime: firebase.database.ServerValue.TIMESTAMP,
        });
      await updateDueDateHook(v);
    });
    zafClient.on("hl_set_ticket", (ticket) => this.setTicket(ticket));
    zafClient.on("hl_reset_ticket", () => this.resetTicket());
    zafClient.on("hl_user_interaction", () => this.resetIdleTimer());
    zafClient.on("hl_set_status", (status) => this.setStatus(status, true));
    zafClient.on("hl_get_agent", (location) =>
      triggerToFrame(location, "hl_agent_update", this.agent)
    );
    // navigate to/action the ticket based upon the notification message posted by service worker
    navigator.serviceWorker.addEventListener("message", (e) => {
      if (
        e.data &&
        e.data.firebaseMessaging &&
        e.data.firebaseMessaging.type === "notification-clicked" &&
        e.data.firebaseMessaging.payload &&
        e.data.firebaseMessaging.payload.data &&
        e.data.firebaseMessaging.payload.data.ticket_id
      ) {
        // no action, the main notification body was clicked
        if (!e.data.firebaseMessaging.action) {
          console.log(
            "ticket click for",
            e.data.firebaseMessaging.payload.data.ticket_id
          );
          zafClient.invoke(
            "routeTo",
            "ticket",
            e.data.firebaseMessaging.payload.data.ticket_id
          );
        } else if (e.data.firebaseMessaging.action === "claim") {
          console.log(
            "claim ticket",
            e.data.firebaseMessaging.payload.data.ticket_id
          );
        }
      }
    });
  }
  async componentDidMount() {
    try {
      ({ currentUser: this.currentUser } = await zafClient.get("currentUser"));
      ({
        "currentUser.customField:agent_slack_user_id":
          this.currentUser.agent_slack_user_id,
      } = await zafClient.get("currentUser.customField:agent_slack_user_id"));
      const permission = await Notification.requestPermission();

      console.log("zendesk user", this.currentUser);
      console.log("notification permission", permission);

      // save the user to local storage (can be used outside of zendesk)
      localStorage.setItem("user", JSON.stringify(this.currentUser));

      // TODO: check localStorage if explicitly unsubscribed?
      if (permission === "denied") {
        zafClient.invoke("instances.create", {
          location: "modal",
          size: {
            width: "500px",
            height: "250px",
          },
          url: `${process.env.REACT_APP_BASE_URL}/permission/modal`,
        });
        return;
      }

      const token = await firebase.messaging().getToken();
      await firebase
        .database()
        .ref(`agents/${this.currentUser.id}`)
        .update({
          zendeskSupportTime: firebase.database.ServerValue.TIMESTAMP,
          id: this.currentUser.id,
          name: this.currentUser.name,
          email: this.currentUser.email,
          avatar: this.currentUser.avatarUrl,
          groups: this.currentUser.groups.map((v) => v.id),
          //slack_id: this.currentUser.agent_slack_user_id,
          [`tokens/${token}`]: firebase.database.ServerValue.TIMESTAMP,
        });
      // set user offline once disconnected from database
      firebase
        .database()
        .ref(`agents/${this.currentUser.id}`)
        .onDisconnect()
        .update({
          zendeskSupportTime: null,
          status: "offline",
          statusTime: firebase.database.ServerValue.TIMESTAMP,
          statusAlertTime: null,
        });
      this.setRefs();
      this.setListeners();

      // set managers offline by default, other agents ready
      if (this.isManagerUser()) {
        this.setStatus("busy");
      } else {
        this.setStatus("ready", true);
      }

      // preload the top bar app & set the correct icon
      invokeToFrame("top_bar", "preloadPane");
    } catch (e) {
      // TODO - check/warn if non-supported browser (Safari / IE?)
      console.log("Error in background page", e);
      // database issue, pop a login modal
      if (e.code === "PERMISSION_DENIED") {
        zafClient.invoke("instances.create", {
          location: "modal",
          size: {
            width: "500px",
            height: "250px",
          },
          url: `${process.env.REACT_APP_BASE_URL}/login`,
        });
      }
    }
  }
  componentWillUnmount() {
    if (this.agentRef) {
      this.agentRef.off();
    }
    if (this.heartbeatRef) {
      this.heartbeatRef.off();
    }
  }
  render() {
    // this is a background page - there is nothing to render!
    return <div>Background Page</div>;
  }
}

export default BackgroundSupport;
