import { firebasePublicVapidKey } from "./../constants/firebase";

// The Firebase client is imported within the index.html file.

let client;

if (typeof firebase === "undefined") {
  throw new Error("Firebase client not found");
} else {
  client = window.firebase;
  client.initializeApp({
    apiKey: "AIzaSyAGVpe-b-JhW-X02e-GKFvFRcnQnKEsUS0",
    appId: "1:1054855425615:web:a4d1d9eaaab504d8a0843a",
    authDomain: "agents.honeylove.com",
    databaseURL: "https://honeylove-zendesk-app.firebaseio.com",
    measurementId: "G-LQ5YDVL0RB",
    messagingSenderId: "1054855425615",
    projectId: "honeylove-zendesk-app",
    storageBucket: "honeylove-zendesk-app.appspot.com",
  });
  // various authentication issues if we don't do this...
  const messaging = client.messaging();
  if (!messaging.vapidKey) {
    messaging.usePublicVapidKey(firebasePublicVapidKey);
  }
}

export default client;
