import React, { Component } from 'react';
import AgentPhones from './AgentPhones';
import Layout from './Layout';
import { triggerToFrame } from '../utils/frames';

import zafClient from '../api/zafClient';
import firebase from '../api/firebase';

class PhoneDashboard extends Component {
    async componentDidMount() {
        if (!zafClient) {
            return;
        }
        try {
            const database = firebase.database();
            const { currentUser } = await zafClient.get('currentUser');
            // do we also need zafClient.off('pane.activated') on component unmount?
            zafClient.on('pane.activated', async v => {
                await database.ref(`agents/${currentUser.id}`).update({
                    navTime: firebase.database.ServerValue.TIMESTAMP
                });
            });

            triggerToFrame('background', 'hl_user_interaction', 'dashboard');

        } catch (e) {
            console.log('error trying to update user state', e);
        }
    }
    render() {
        return (
            <Layout showHeader={true} noTopPadding={true}>
                <AgentPhones />
            </Layout>
        );
    }
}

export default PhoneDashboard;