import React, { Component } from 'react';
import firebase from './../api/firebase';
import firebaseui from './../api/firebaseui';
import { getQueryStringParams } from './../utils/qsp';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import {
    Chrome,
    Body,
    Content
} from '@zendeskgarden/react-chrome';
import { Dots } from '@zendeskgarden/react-loaders';


class Login extends Component {
    state = {
        isSignedIn: undefined,
        isSigningIn: false,
        loaded: false
    };
    async componentDidMount() {
        const uiConfig = {
            signInOptions: [
                {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    customParameters: {
                        hd: 'honeylove.com'
                    }
                }
            ],
            tosUrl: 'https://www.honeylove.com/pages/terms-of-service',
            privacyPolicyUrl: 'https://www.honeylove.com/pages/privacy-policy'
        };
        const qsp = getQueryStringParams(this.props.location.search);
        if (qsp.from) {
            console.log('success url', qsp.from);
            uiConfig.signInSuccessUrl = qsp.from;
        }
        // Initialize the FirebaseUI Widget using Firebase.
        const ui = new firebaseui.auth.AuthUI(firebase.auth());
        //ui.start('#auth-container', uiConfig);
        //console.log('isPendingRedirect', ui.isPendingRedirect());
        this.setState({
            isSigningIn: ui.isPendingRedirect()
        });
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
            //console.log('got user',user);
            this.setState({
                isSignedIn: !!user,
                isSigningIn: ui.isPendingRedirect(),
                loaded: true
            });
            if (!user) {
                ui.start('#auth-container', uiConfig);
            }
            if (user) {
                firebase.analytics().logEvent('login'); // or sign_up?
            }
        });
    }
    componentWillUnmount() {
        this.unregisterAuthObserver();
    }
    render() {
        return (
            <Chrome>
                <Body>
                    <Content>
                        <Grid>
                            <Row alignItems="center" style={{ minHeight: '70vh' }}>
                                <Col md={4}></Col>
                                <Col md={4} style={{ textAlign: 'center' }}>
                                    {!this.state.loaded ?
                                        <Dots size="50px" />
                                        : this.state.isSignedIn ?
                                            <div>
                                                <div style={{ marginBottom: 25 }}>Hello, {firebase.auth().currentUser.displayName} &lt;{firebase.auth().currentUser.email}&gt;</div>
                                                <Button isPrimary={true} onClick={() => firebase.auth().signOut()}>Sign Out</Button>
                                            </div> : <div>
                                                {!this.state.isSigningIn &&
                                                    <div>You are not signed in.</div>
                                                }
                                                <div id="auth-container"></div>
                                            </div>
                                    }
                                </Col>
                                <Col md={4}></Col>
                            </Row>
                        </Grid>
                    </Content>
                </Body>
            </Chrome>
        );
    }
}

export default Login;