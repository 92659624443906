import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Loading from './Loading';
import Layout from './Layout';
import {
    Toggle,
    Field,
    Label,
    Hint
} from '@zendeskgarden/react-forms';
import { Button } from '@zendeskgarden/react-buttons';
import { Paragraph } from '@zendeskgarden/react-typography';
import { Notification, Title } from '@zendeskgarden/react-notifications';

import zafClient from './../api/zafClient';
import firebase from './../api/firebase';

const StyledStrong = styled.strong`
    font-weight: ${p => p.theme.fontWeights['semibold']};
    color: ${p => p.theme.palette.crimson[400]};
`;

class Settings extends Component {
    ref = null;
    state = {
        groups: {},
        inZendesk: true,
        loggedIn: false,
        token: null,
        loaded: false,
        always_show_notifications: '',
        hide_all_notifications: ''
    }
    async save_service_setting(val, name) {
        const messaging = firebase.messaging();
        const worker = await messaging.getServiceWorkerRegistration();
        if (worker && worker.active) {
            worker.active.postMessage({
                message: 'set_value',
                name,
                value: val ? '1' : ''
            });
        }
    }
    componentWillUnmount() {
        if (this.ref) {
            this.ref.off();
        }
        navigator.serviceWorker.removeEventListener('message', this.getServiceWorkerMessage);
        this.unregisterAuthObserver();
    }
    getServiceWorkerMessage(e) {
        if (e.data && e.data.serviceWorkerMessage && e.data.serviceWorkerMessage.message === 'get_value') {
            this.setState({ [e.data.serviceWorkerMessage.name]: e.data.serviceWorkerMessage.value });
        }
    }
    async componentDidMount() {
        try {
            // need to listen for service worker messages with setting details
            navigator.serviceWorker.addEventListener('message', this.getServiceWorkerMessage.bind(this));
            this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
                //console.log('got user', user);
                this.setState({
                    loggedIn: !!user
                });
            });
            const messaging = firebase.messaging();
            const worker = await messaging.getServiceWorkerRegistration();
            if (worker && worker.active) {
                worker.active.postMessage({ message: 'get_value', name: 'always_show_notifications' });
                worker.active.postMessage({ message: 'get_value', name: 'hide_all_notifications' });
            }
            //messaging.onTokenRefresh();
            //messaging.deleteToken(); // if there is a token error?
            const token = await messaging.getToken();
            this.setState({ token });
            // TODO - option to alert outside of business hour
        } catch (e) {
            console.log(e);
            this.setState({
                token: null
            });
        }
        // zendesk client isn't running, probably not in an iframe
        if (!zafClient) {
            this.setState({
                loaded: true,
                inZendesk: false
            });
            return;
        }
        /*
        // TODO - offer agent ability to opt in/out of groups
        try {
            // get a list of all possible groups
            const groupMap = {};
            const { groups } = await zafClient.request({
                url: '/api/v2/groups.json',
                type: 'GET'
            });
            groups.map(v => {
                groupMap[v.id] = v;
                return v;
            });
            this.setState({
                groups: groupMap
            });
        } catch (e) {
            console.log('Unable to get groups', e);
        }
        */
        this.setState({
            loaded: true
        });
    }
    render() {
        if (!this.state.loaded) {
            return <Loading />;
        }
        return (
            <Layout showHeader={true} headline="Settings">
                {!this.state.token &&
                    <Notification type="error">
                        <Title>Permission Required</Title>
                        <Paragraph>
                            You have not granted notification permissions!
                        </Paragraph>
                        {this.state.inZendesk ?
                            <Paragraph><Link to="/permission/grant">Click here</Link> to continue.</Paragraph>
                            :
                            <Paragraph><a href="/permission/grant" target="token_permission">Click here</a> to continue.</Paragraph>
                        }

                    </Notification>
                }
                <Paragraph>&nbsp;</Paragraph>
                <Field>
                    <Toggle defaultChecked={this.state.always_show_notifications} onChange={event => this.save_service_setting(event.target.checked, 'always_show_notifications')}>
                        <Label>Always show notifications</Label>
                        <Hint>Pop notifications in the background, even if no Zendesk tab is open.</Hint>
                    </Toggle>
                </Field>
                <Paragraph>&nbsp;</Paragraph>
                <Field>
                    <Toggle defaultChecked={this.state.hide_all_notifications} onChange={event => this.save_service_setting(event.target.checked, 'hide_all_notifications')}>
                        <Label>Hide all notifications</Label>
                        <Hint><StyledStrong>CAUTION!</StyledStrong> This setting will prevent all notifications from appearing.</Hint>
                    </Toggle>
                </Field>
                {this.state.loggedIn &&
                    <div style={{ marginTop: 25 }}>
                        <div style={{ marginBottom: 25 }}>Logged in as {firebase.auth().currentUser.displayName} &lt;{firebase.auth().currentUser.email}&gt;</div>
                        <Button isPrimary={true} onClick={() => firebase.auth().signOut()}>Sign Out</Button>
                    </div>
                }
            </Layout>
        );
    }
}

export default Settings;