import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import firebase from './../api/firebase';

class PublicRoute extends Component {
    componentDidMount() {
        firebase.analytics().setCurrentScreen(this.props.component.name);
        firebase.analytics().logEvent('screen_view');
    }
    render() {
        const { component: Component, ...rest } = this.props;
        firebase.analytics().setCurrentScreen(Component.name);
        return (
            <Route {...rest} render={props =>
                <Component {...props} />
            }
            />
        );
    }
}

export default withRouter(PublicRoute);