import React, { Component } from "react";

class CountUp extends Component {
    // react warns of setting state even with timer cleared on componentWillUnmount!
    _isMounted = false;

    constructor(props) {
        super(props);
        const currentTime = this.nearestMs(new Date().getTime())
        this.state = {
            startTime: this.props.startTime ? this.nearestMs(this.props.startTime) : this.props.startOnLoad ? currentTime : 0,
            elapsedTime: this.props.startTime && currentTime > this.nearestMs(this.props.startTime) ? currentTime - this.nearestMs(this.props.startTime) : 0
        };
    }

    // limit timestamps to nearest 1000 ms to prevent out of sync timers
    nearestMs(v) {
        if (!v) {
            return;
        }
        return (v / 1000).toFixed() * 1000;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.state.startTime) {
            this.startCounting();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.timer);
    }

    componentDidUpdate(prevProps, prevState) {
        const currentTime = this.nearestMs(new Date().getTime())
        if (prevProps.startTime !== this.props.startTime) {
            this.setState({
                startTime: this.nearestMs(this.props.startTime) || currentTime,
                elapsedTime: this.props.startTime && currentTime > this.nearestMs(this.props.startTime) ? currentTime - this.nearestMs(this.props.startTime) : 0
            });
        }
    }

    startCounting() {
        this.timer = setInterval(this.countUp.bind(this), 1000);
    }

    countUp() {
        if (this._isMounted) {
            this.setState({
                startTime: this.state.startTime,
                elapsedTime: this.state.startTime ? this.nearestMs(new Date().getTime()) - this.state.startTime : 0
            });
        }
    }

    render() {
        const seconds = ('0' + (Math.floor(this.state.elapsedTime / 1000) % 60)).slice(-2);
        const minutes = ('0' + (Math.floor(this.state.elapsedTime / 60000) % 60)).slice(-2);
        const hours = ('0' + Math.floor(this.state.elapsedTime / 3600000)).slice(-2);
        const days = Math.floor(this.state.elapsedTime / 86400000);
        if (this.state.startTime) {
            if (days > 0) {
                return <span>
                    {days} Day{days > 1 ? 's' : ''}
                </span>
            }
            return (
                <span>
                    {hours}:{minutes}:{seconds}
                </span>
            );
        }
        return '';
    }
}

export default CountUp;