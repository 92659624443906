import React, {Component} from 'react';
import { Dots } from '@zendeskgarden/react-loaders';

class Loading extends Component {
    render() {
        // loading indicator, assume we have the full viewport
        return (
            <Dots size="50px" style={{position: 'fixed', zIndex: '1000', top: '40%', left: '50%', marginLeft: -25, textAlign: 'center'}} />
        );
    }
}

export default Loading;