import React, { Component } from 'react';
import { Alert, Paragraph, Title } from '@zendeskgarden/react-notifications';
import { Button } from '@zendeskgarden/react-buttons';

import zafClient from '../../api/zafClient';

class PermissionModal extends Component {
    render() {
        return (
            <div>
                <Alert type="error">
                    <Title>Notification Permission Required</Title>
                    <Paragraph>You must grant permissions to your web browser for notifications to work.</Paragraph>
                    <Paragraph>Reload this window after permissions are granted.</Paragraph>
                </Alert>
                <Button
                    style={{ marginTop: 20 }}
                    isDanger={true}
                    isStretched={true}
                    onClick={() => {
                        window.open('/permission/grant', 'token_permission');
                        // close the modal, otherwise weird issues with things opening again here
                        zafClient.invoke('destroy');
                    }}
                >Grant Permissions</Button>
            </div>
        );
    }
}

export default PermissionModal;