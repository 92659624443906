import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';
import PrivateRoute from './router/PrivateRoute';
import PublicRoute from './router/PublicRoute';

import Login from './components/Login';
import SidebarTicket from './components/SidebarTicket';
import SidebarChat from './components/SidebarChat';
import SidebarUser from './components/SidebarUser';
import TopbarSupport from './components/TopbarSupport';
import BackgroundSupport from './components/BackgroundSupport';
import BackgroundChat from './components/BackgroundChat';
import AgentDashboard from './components/AgentDashboard';
import PhoneDashboard from './components/PhoneDashboard';
import Settings from './components/Settings';
import PermissionModal from './components/permission/PermissionModal';
import PermissionGrant from './components/permission/PermissionGrant';
import { ThemeProvider } from '@zendeskgarden/react-theming';

// TODO - add phone dashboard
class App extends Component {
  render() {
    return (
      <ThemeProvider>
        <Router>
          <Switch>
            <PrivateRoute path="/dashboard/phone" component={PhoneDashboard} />
            <PrivateRoute path="/dashboard" component={AgentDashboard} />
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/sidebar/ticket" component={SidebarTicket} />
            <PublicRoute path="/sidebar/chat" component={SidebarChat} />
            <PublicRoute path="/sidebar/user" component={SidebarUser} />
            <PublicRoute path="/topbar/support" component={TopbarSupport} />
            <PublicRoute path="/background/support" component={BackgroundSupport} />
            <PublicRoute path="/background/chat" component={BackgroundChat} />
            <PublicRoute path="/permission/modal" component={PermissionModal} />
            <PrivateRoute path="/permission/grant" component={PermissionGrant} />
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute path="/" component={AgentDashboard} />
          </Switch>
        </Router>
      </ThemeProvider>
    )
  }
}

export default App;
