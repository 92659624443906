import React, { Component } from "react";
import { DateTime } from "luxon";
import zafClient from "../api/zafClient";
import firebase from "../api/firebase";
import { getTicketParams } from "../utils/objectparams";
import { triggerToFrame } from "../utils/frames";

class SidebarTicket extends Component {
  state = {
    fbURL: null,
    date: new Date(),
    showClock: false,
  };
  ticket = {};
  updateDate = this.updateDate.bind(this);
  loadFbLoginApi() {
    window.fbAsyncInit = function () {
      window.FB.init({
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: "v2.7",
      });
    };
    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }
  getDueDate() {
    if (this.ticket.followUpDate && this.ticket.followUpTime) {
      const ymd = new Date(this.ticket.followUpDate)
        .toISOString()
        .substring(0, 10);
      const hma = this.ticket.followUpTime.replace("_", ":");
      const iso = DateTime.fromFormat(`${ymd} ${hma}`, "yyyy-MM-dd h:mma", {
        zone: "America/Los_Angeles",
      })
        .toUTC()
        .toISO();
      return iso;
    }
    return "";
  }
  updateDate() {
    this.setState({
      date: new Date(),
    });
  }
  async componentDidMount() {
    try {
      // TODO - warn / pop the top bar if we are in an offline state
      const { currentUser } = await zafClient.get("currentUser");
      ({ ticket: this.ticket } = await zafClient.get("ticket"));
      ({ channel: this.ticket.channel } = await zafClient.get("channel"));
      // add the time the sidebar for this ticket is first loaded
      this.ticket.ticketNavTime = new Date().getTime();
      try {
        ({
          "ticket.customField:custom_field_360031517732":
            this.ticket.followUpDate,
        } = await zafClient.get([
          "ticket.customField:custom_field_360031517732",
        ]));
        ({
          "ticket.customField:custom_field_360031550171":
            this.ticket.followUpTime,
        } = await zafClient.get([
          "ticket.customField:custom_field_360031550171",
        ]));
        ({
          "ticket.requester.customField:shopify_customer_id":
            this.ticket.requester.shopify_customer_id,
        } = await zafClient.get([
          "ticket.requester.customField:shopify_customer_id",
        ]));
      } catch (e) {
        console.error("Error setting custom field", e);
      }
      if (this.ticket.type === "task") {
        ({ "ticket.customField:due_date": this.ticket.dueDate } =
          await zafClient.get("ticket.customField:due_date"));
        zafClient.invoke("ticketFields:due_date.hide");
      }

      console.log("ticket", this.ticket);

      triggerToFrame("background", "hl_set_ticket", this.ticket);
      triggerToFrame("background", "hl_user_interaction", "load");

      sessionStorage.setItem("ticket", JSON.stringify(this.ticket));

      await firebase
        .database()
        .ref(`agents/${currentUser.id}`)
        .update({
          ticket: getTicketParams(this.ticket),
          chat: {},
          navTime: firebase.database.ServerValue.TIMESTAMP,
        });
      // clear out the ticket ID when this connection is closed
      firebase
        .database()
        .ref(`agents/${currentUser.id}`)
        .onDisconnect()
        .update({
          navTime: firebase.database.ServerValue.TIMESTAMP,
        });
      zafClient.on("app.activated", async (v) => {
        ({ ticket: this.ticket } = await zafClient.get("ticket"));
        ({ channel: this.ticket.channel } = await zafClient.get("channel"));
        triggerToFrame("background", "hl_set_ticket", this.ticket);
        triggerToFrame("background", "hl_user_interaction", "load");
        sessionStorage.setItem("ticket", JSON.stringify(this.ticket));
        await firebase
          .database()
          .ref(`agents/${currentUser.id}`)
          .update({
            ticket: getTicketParams(this.ticket),
            chat: {},
            navTime: firebase.database.ServerValue.TIMESTAMP,
          });
      });
      // FYI - triggers will not fire when the last ticket tab is closed!
      // The background frame handles clearing tickets based upon the onDisconnect update
      zafClient.on("app.deactivated", async (v) => {
        triggerToFrame("background", "hl_reset_ticket");
        triggerToFrame("background", "hl_user_interaction", "unload");
      });
      /*
      // listen for take it!
      zafClient.on('ticket.assignee.user.id.changed', async v => {
        console.log('ticket.assignee.user.id.changed', v);
      });
      */
      zafClient.on("*.changed", async (v) => {
        triggerToFrame("background", "hl_user_interaction", "ticket_changes");
        if (v.propertyName === "channel") {
          this.ticket.channel = v.newValue;
          triggerToFrame("background", "hl_set_ticket", this.ticket);
        }
      });
      // on follow up date field change
      zafClient.on("ticket.custom_field_360031517732.changed", async (v) => {
        this.ticket.followUpDate = v;
        await zafClient.set("ticket.type", "task");
        await zafClient.set("ticket.customField:due_date", this.getDueDate());
      });
      // on follow up time field change
      zafClient.on("ticket.custom_field_360031550171.changed", async (v) => {
        this.ticket.followUpTime = v;
        if (!this.ticket.followUpDate) {
          await zafClient.set(
            "ticket.customField:custom_field_360031517732",
            DateTime.local().toFormat("yyyy-MM-dd")
          );
        }
        await zafClient.set("ticket.type", "task");
        // NOTE - due date will not save with time, update in background with ticket.saved
        await zafClient.set("ticket.customField:due_date", this.getDueDate());
        await zafClient.set("ticket.status", "pending");
      });
      zafClient.on("ticket.due_date.changed", async (v) => {
        if (!v) {
          await zafClient.invoke("ticket.tags.remove", "pending-task");
          return;
        } else if (new Date(v) < new Date()) {
          await zafClient.invoke(
            "notify",
            "Follow up date/time must be in future!",
            "error",
            4000
          );
          await zafClient.invoke("ticket.tags.remove", "pending-task");
          await zafClient.invoke("disableSave");
        } else {
          await zafClient.invoke("ticket.tags.add", "pending-task");
          await zafClient.invoke("enableSave");
        }
      });
      // send out another push notification so agents know this was viewed/claimed
      if (
        this.ticket.viewers.length === 0 &&
        this.ticket.tags.indexOf("honeylove-notification-sent") > -1 &&
        this.ticket.tags.indexOf("honeylove-notification-viewed") === -1
      ) {
        const claimTicket = firebase.functions().httpsCallable("claimTicket");
        const result = await claimTicket({
          ticket_id: this.ticket.id,
          ticket_via:
            this.ticket.via && this.ticket.via.channel
              ? this.ticket.via.channel
              : null,
          ticket_group_id:
            this.ticket.assignee &&
            this.ticket.assignee.group &&
            this.ticket.assignee.group.id
              ? this.ticket.assignee.group.id
              : null,
          created_at: this.ticket.createdAt,
          viewer_id: currentUser.id,
          viewer_name: currentUser.name,
        });
        console.log("claimTicket", result);
        await zafClient.invoke(
          "ticket.tags.add",
          "honeylove-notification-viewed"
        );
      }
      zafClient.invoke("hide");
      // look for a link to a FB post, show in sidebar if found
      if (
        this.ticket.via &&
        this.ticket.via.channel === "any_channel" &&
        this.ticket.comments.length > 0
      ) {
        const firstComment =
          this.ticket.comments[this.ticket.comments.length - 1];
        const linkMatches =
          firstComment.value.match(
            /href="(https:\/\/www.facebook.com\/[^"]+)"/
          ) ||
          firstComment.value.match(
            /href="(https:\/\/business.facebook.com\/[^"]+)"/
          ) ||
          [];
        if (linkMatches[1]) {
          zafClient.invoke("show");
          //await zafClient.set('comment.type', 'internalNote');
          this.setState({ fbURL: linkMatches[1] }, () => {
            this.loadFbLoginApi();
          });
        }
      } else if (
        this.ticket.requester.shopify_customer_id ||
        this.ticket.requester.timeZone.ianaName !== "America/Los_Angeles"
      ) {
        zafClient.invoke("show");
        zafClient.invoke("resize", { height: "75px" });
        this.setState({ showClock: true });
        this.interval = setInterval(this.updateDate, 1000);
        const hour = this.state.date.toLocaleTimeString([], {
          hour: "2-digit",
          hour12: false,
          timeZone: this.ticket.requester.timeZone.ianaName,
        });
        if (hour < 9 || hour >= 18) {
          zafClient.invoke(
            "notify",
            "Warning! It is currently outside of 9am - 6pm contact hours in the local timezone.",
            "error",
            10000
          );
        }
      }
      //zafClient.invoke('ticketFields:comment.disable');
      // TODO - check for ticket.updated event to see if someone else has updated this ticket?
    } catch (e) {
      console.log("Error in sidebar page", e);
      // database issue, pop a login modal
      if (e.code === "PERMISSION_DENIED") {
        zafClient.invoke("instances.create", {
          location: "modal",
          size: {
            width: "500px",
            height: "250px",
          },
          url: `${process.env.REACT_APP_BASE_URL}/login`,
        });
      }
    }
  }
  render() {
    if (this.state.fbURL) {
      return (
        <div>
          <div id="fb-root"></div>
          <div
            className="fb-post"
            data-href={this.state.fbURL}
            data-width="auto"
          ></div>
        </div>
      );
    } else if (this.state.showClock) {
      return (
        <div>
          <div>{this.ticket.requester.timeZone.name}</div>
          <div style={{ fontSize: 24, marginTop: 5 }}>
            {this.state.date.toLocaleTimeString("en-US", {
              timeZone: this.ticket.requester.timeZone.ianaName,
            })}
          </div>
          <div style={{ fontSize: 10, marginTop: 5 }}>
            {this.ticket.requester.shopify_customer_id
              ? "Based on Shopify address record"
              : "Based on assumed timezone"}
          </div>
        </div>
      );
    }
    // by default this is a hidden sidebar used to get ticket context
    return <div></div>;
  }
}

export default SidebarTicket;
