import React, { Component } from "react";
import { Grid, Row } from "@zendeskgarden/react-grid";
import { Avatar } from "@zendeskgarden/react-avatars";
import {
  Table,
  Caption,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Row as TableRow,
  Cell,
} from "@zendeskgarden/react-tables";
import { Tooltip, Title, Paragraph } from "@zendeskgarden/react-tooltips";
import { XL, SM } from "@zendeskgarden/react-typography";
import { Toggle, Field, Label } from "@zendeskgarden/react-forms";

import firebase from "./../api/firebase";

class AgentPhones extends Component {
  agentRef = null;
  heartbeatRef = null;
  groups = {};
  state = {
    agents: [],
    showInactive: false,
    showPhone: false,
    lastUpdate: null,
    inZendesk: true,
  };
  async componentDidMount() {
    try {
      // grab the group names/definitions
      await firebase
        .database()
        .ref("groups")
        .once("value", (snap) => {
          snap.forEach((child) => {
            this.groups[child.val()["id"]] = child.val();
          });
        });
      // subscribe to the agent & heartbeat changes
      this.setRefs();
    } catch (e) {}
  }
  setRefs() {
    if (this.agentRef) {
      this.agentRef.off();
    }
    if (this.heartbeatRef) {
      this.heartbeatRef.off();
    }
    this.agentRef = firebase.database().ref("agents");
    // note - ordering is used for filtering so have to filter out
    this.agentRef.orderByChild("name").on("value", (snap) => {
      let agents = [];
      snap.forEach((child) => {
        const v = child.val();
        if (!v.talk) {
          return;
        }
        if (!this.state.showInactive && !v.talk.online_time) {
          return;
        }
        agents.push(child.val());
      });
      this.setState({
        agents,
      });
    });
    this.heartbeatRef = firebase.database().ref("heartbeat/voice_availability");
    this.heartbeatRef.on("value", (snap) => {
      this.setState({
        //showPhone: (snap.val() > new Date().getTime() - 90000)
        lastUpdate: snap.val().timestamp,
        showPhone: snap.val().active,
      });
    });
  }
  formatTime(s) {
    const seconds = ("0" + (Math.floor(s) % 60)).slice(-2);
    const minutes = ("0" + (Math.floor(s / 60) % 60)).slice(-2);
    const hours = ("0" + Math.floor(s / 3600)).slice(-2);
    const days = Math.floor(s / 86400);
    if (days > 0) {
      return `${days} Day${days > 1 ? "s" : ""}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  }
  toggleInactive(inactive) {
    this.setState(
      {
        showInactive: inactive,
      },
      () => {
        this.setRefs();
      }
    );
  }
  componentWillUnmount() {
    if (this.agentRef) {
      this.agentRef.off();
    }
    if (this.heartbeatRef) {
      this.heartbeatRef.off();
    }
  }
  render() {
    return (
      <Grid>
        <Row alignItems="center" justifyContent="end" style={{ minHeight: 50 }}>
          <Field style={{ marginRight: 15 }}>
            <Toggle
              defaultChecked={false}
              onClick={(event) => this.toggleInactive(event.target.checked)}
            >
              <Label>Include inactive agents</Label>
            </Toggle>
          </Field>
        </Row>
        <Row>
          <Table>
            <Caption>
              <XL>Agent Activity</XL>
            </Caption>
            <Head>
              <HeaderRow>
                <HeaderCell width="56px"></HeaderCell>
                <HeaderCell isTruncated={true}>Agent</HeaderCell>
                {this.state.showPhone && (
                  <HeaderCell isTruncated={true}>Status</HeaderCell>
                )}
                <HeaderCell isTruncated={true}>
                  Total Online
                  <br /> Time
                </HeaderCell>
                <HeaderCell isTruncated={true}>
                  Time
                  <br />
                  Available
                </HeaderCell>
                <HeaderCell isTruncated={true}>
                  Total Talk
                  <br />
                  Time
                </HeaderCell>
                <HeaderCell isTruncated={true}>
                  Total Wrap
                  <br />
                  Up Time
                </HeaderCell>
                <HeaderCell isTruncated={true}>
                  Calls
                  <br />
                  Accepted
                </HeaderCell>
                <HeaderCell isTruncated={true}>
                  Calls
                  <br />
                  Missed
                </HeaderCell>
                <HeaderCell isTruncated={true}>
                  Calls
                  <br />
                  Declined
                </HeaderCell>
              </HeaderRow>
            </Head>
            <Body>
              {this.state.agents.map((v) => {
                return (
                  <TableRow key={v.id} isSelected={v.talk.calls_missed > 0}>
                    <Cell>
                      <Avatar size="small" backgroundColor="#C2C8CC">
                        {v.avatar && !v.avatar.includes("HL-logo-gray.png") ? (
                          <img src={v.avatar} alt={v.name} />
                        ) : (
                          <Avatar.Text>
                            {v.name &&
                              v.name.split(" ").map((val, idx) => {
                                if (typeof val === "string" && val[0]) {
                                  return (
                                    <React.Fragment key={idx}>
                                      {val[0].toUpperCase()}
                                    </React.Fragment>
                                  );
                                }
                                return "";
                              })}
                          </Avatar.Text>
                        )}
                      </Avatar>
                    </Cell>
                    <Cell isTruncated={true}>{v.name}</Cell>
                    {this.state.showPhone && (
                      <Cell isTruncated={true}>
                        {["on_call", "wrap_up"].indexOf(v.talk.status) > -1
                          ? v.talk.status
                          : v.talk.agent_state}
                      </Cell>
                    )}
                    <Cell isTruncated={true}>
                      {this.formatTime(v.talk.online_time)}
                    </Cell>
                    <Cell isTruncated={true}>
                      {this.formatTime(v.talk.available_time)}
                    </Cell>
                    <Cell isTruncated={true}>
                      <Tooltip
                        type="light"
                        size="large"
                        placement="top-end"
                        content={
                          <>
                            <Title>Avg Talk Time</Title>
                            <Paragraph>
                              {this.formatTime(v.talk.average_talk_time)}
                            </Paragraph>
                          </>
                        }
                      >
                        <span>{this.formatTime(v.talk.total_talk_time)}</span>
                      </Tooltip>
                    </Cell>
                    <Cell isTruncated={true}>
                      <Tooltip
                        type="light"
                        size="large"
                        placement="top-end"
                        content={
                          <>
                            <Title>Avg Wrap Up</Title>
                            <Paragraph>
                              {this.formatTime(v.talk.average_wrap_up_time)}
                            </Paragraph>
                          </>
                        }
                      >
                        <span>
                          {this.formatTime(v.talk.total_wrap_up_time)}
                        </span>
                      </Tooltip>
                    </Cell>
                    <Cell isTruncated={true}>{v.talk.calls_accepted}</Cell>
                    <Cell isTruncated={true}>{v.talk.calls_missed}</Cell>
                    <Cell isTruncated={true}>{v.talk.calls_denied}</Cell>
                  </TableRow>
                );
              })}
            </Body>
          </Table>
        </Row>
        {this.state.lastUpdate && (
          <Row justifyContent="end" style={{ marginTop: 20 }}>
            <SM>
              Last update: {new Date(this.state.lastUpdate).toTimeString()}
            </SM>
          </Row>
        )}
      </Grid>
    );
  }
}

export default AgentPhones;
