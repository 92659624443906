import React, { Component } from 'react';
import CountUp from './CountUp'
import JavascriptTimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import en from 'javascript-time-ago/locale/en';
import { ReactComponent as PlayIcon } from '@zendeskgarden/svg-icons/src/12/play-circle-stroke.svg';
import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { Well } from '@zendeskgarden/react-notifications';
import { Avatar } from '@zendeskgarden/react-avatars';
import { Tag } from '@zendeskgarden/react-tags';
import {
    Toggle,
    Field,
    Label
} from '@zendeskgarden/react-forms';
import { getStatus } from '../utils/status'

import zafClient from './../api/zafClient';
import firebase from './../api/firebase';

JavascriptTimeAgo.locale(en);

class AgentTiles extends Component {
    agentRef = null;
    heartbeatRef = null;
    groups = {};
    state = {
        agents: [],
        showExtended: false,
        showOffline: false,
        showPhone: false,
        inZendesk: true
    }
    async componentDidMount() {
        try {
            // grab the group names/definitions
            await firebase.database().ref('groups').once('value', snap => {
                snap.forEach(child => {
                    this.groups[child.val()['id']] = child.val();
                });
            });
            // subscribe to the agent & heartbeat changes
            this.setRefs();
        } catch (e) {
        }
    }
    setRefs() {
        if (this.agentRef) {
            this.agentRef.off();
        }
        if (this.heartbeatRef) {
            this.heartbeatRef.off();
        }
        this.agentRef = firebase.database().ref('agents');
        // note - ordering is used for filtering so have to filter out 
        this.agentRef.orderByChild('name').on('value', snap => {
            let agents = [];
            snap.forEach(child => {
                const v = child.val();
                let offline = (v.status === 'offline' || !v.status);
                if (v.talk && v.talk.agent_state && v.talk.agent_state !== 'offline') {
                    offline = false;
                }
                if (!this.state.showOffline && offline) {
                    return;
                }
                agents.push(child.val());
            });
            this.setState({
                agents
            })
        });
        this.heartbeatRef = firebase.database().ref('heartbeat/voice_availability/active');
        this.heartbeatRef.on('value', snap => {
            this.setState({
                //showPhone: (snap.val() > new Date().getTime() - 90000)
                showPhone: snap.val()
            })
        });
    }
    toggleExtended(extended) {
        this.setState({
            showExtended: extended
        }, () => {
            this.setRefs();
        });
    }
    toggleOffline(offline) {
        this.setState({
            showOffline: offline
        }, () => {
            this.setRefs();
        });
    }
    componentWillUnmount() {
        if (this.agentRef) {
            this.agentRef.off();
        }
        if (this.heartbeatRef) {
            this.heartbeatRef.off();
        }
    }
    userClick(id) {
        if (zafClient) {
            zafClient.invoke('routeTo', 'user', id);
        } else {
            window.open(`https://${process.env.REACT_APP_ZENDESK_SUBDOMAIN}.zendesk.com/agent/users/${id}`, 'view_user');
        }
        return false;
    }
    ticketClick(id) {
        if (zafClient) {
            zafClient.invoke('routeTo', 'ticket', id);
        } else {
            window.open(`https://${process.env.REACT_APP_ZENDESK_SUBDOMAIN}.zendesk.com/agent/tickets/${id}`, 'view_ticket');
        }
        return false;
    }
    render() {
        return (
            <Grid>
                <Row alignItems="center" justifyContent="end" style={{ minHeight: 50 }}>
                    <Field style={{ marginRight: 15 }}>
                        <Toggle defaultChecked={false} onClick={(event) => this.toggleExtended(event.target.checked)}>
                            <Label>Show extended details</Label>
                        </Toggle>
                    </Field>
                    <Field style={{ marginRight: 15 }}>
                        <Toggle defaultChecked={false} onClick={(event) => this.toggleOffline(event.target.checked)}>
                            <Label>Include offline agents</Label>
                        </Toggle>
                    </Field>
                </Row>
                <Row alignItems="center">
                    {this.state.agents.map(v => {
                        return (
                            <Col key={v.id} xl={4} md={6} xs={12}>
                                <Well isFloating={true} style={{ marginBottom: 15 }}>
                                    <Avatar
                                        backgroundColor="#C2C8CC"
                                        badge={getStatus(v.status).avatarBadge}
                                        status={getStatus(v.status).avatarStatus}
                                    >
                                        {!v.avatar?.includes('HL-logo-gray.png') ?
                                            <img src={v.avatar} alt={v.name} />
                                            :
                                            <Avatar.Text>
                                                {
                                                    v.name && v.name.split(' ').map((val, idx) => {
                                                        if (typeof val === 'string' && val[0]) {
                                                            return <React.Fragment key={idx}>{val[0].toUpperCase()}</React.Fragment>
                                                        }
                                                        return '';
                                                    })
                                                }
                                            </Avatar.Text>
                                        }
                                    </Avatar>
                                    <span
                                        style={{ cursor: 'pointer', marginLeft: 15, fontSize: '20px' }}
                                        onClick={() => this.userClick(v.id)}>{v.name}</span>
                                    <div style={{ marginTop: 10, borderBottom: '1px solid #EEE', width: '100%' }} />
                                    <Grid gutters="sm" style={{ marginTop: 5 }}>
                                        {v.status === 'in-ticket' || v.status === 'in-chat' ?
                                            <Row>
                                                <Col sm={5} style={{ fontWeight: 'bold' }}>
                                                    {v.status === 'in-ticket' ? 'Ticket' : 'Chat'}
                                                    {v.ticket?.inPlay &&
                                                        <PlayIcon style={{ marginLeft: 3 }} />
                                                    }
                                                </Col>
                                                <Col sm={7}>
                                                    {v.ticket?.id &&
                                                        <span>{v.ticket.id !== 'NEW' ? <Button isLink onClick={() => this.ticketClick(v.ticket.id)}>#{v.ticket.id}</Button> : v.ticket.id}</span>
                                                    }
                                                    {v.ticket?.channel &&
                                                        <span>&nbsp;({v.ticket.channel})</span>
                                                    }
                                                </Col>
                                            </Row>
                                            : <Row>
                                                <Col sm={5} style={{ fontWeight: 'bold' }}>Last Active</Col>
                                                {v.navTime ?
                                                    <Col sm={7}><ReactTimeAgo date={v.navTime} /></Col> : <Col sm={7}>Never</Col>
                                                }
                                            </Row>
                                        }
                                        <Row>
                                            <Col sm={5} style={{ fontWeight: 'bold' }}>Status</Col>
                                            <Col sm={7}>{getStatus(v.status).name}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={5} style={{ fontWeight: 'bold' }}>Status Time</Col>
                                            <Col sm={7}><CountUp startTime={v.statusTime} /></Col>
                                        </Row>
                                        {this.state.showPhone &&
                                            <div>
                                                <Row>
                                                    <Col sm={5} style={{ fontWeight: 'bold' }}>Phone Status</Col>
                                                    {v.talk && v.talk.agent_state &&
                                                        <Col sm={7}>
                                                            {v.talk.status && v.talk.status !== 'not_available' && v.talk.status !== 'available' ?
                                                                <span>{v.talk.status}</span> : <span>{v.talk.agent_state}</span>}
                                                        </Col>
                                                    }
                                                </Row>
                                                {/* <Row>
                                                    <Col sm={5} style={{ fontWeight: 'bold' }}>Phone Time</Col>
                                                    <Col sm={7}>{v.talk && v.talk.agent_state_time &&
                                                        <CountUp startTime={v.talk.agent_state_time} />}</Col>
                                                </Row> */}
                                            </div>
                                        }
                                        {this.state.showExtended &&
                                            <Row>
                                                <Col sm={5} style={{ fontWeight: 'bold' }}>Support Time</Col>
                                                <Col sm={7}><CountUp startTime={v.zendeskSupportTime} /></Col>
                                            </Row>
                                        }
                                        {this.state.showExtended &&
                                            <Row>
                                                <Col sm={5} style={{ fontWeight: 'bold' }}>Chat Time</Col>
                                                <Col sm={7}><CountUp startTime={v.zendeskChatTime} /></Col>
                                            </Row>
                                        }
                                        <Row justifyContent="center" style={{ height: 40, marginTop: 5 }}>
                                            <Col>
                                                {v.groups && v.groups.sort().map(g => {
                                                    if (!this.groups[g]) {
                                                        return null;
                                                    }
                                                    return (<Tag key={`${v.id}-${g}`} style={{ margin: 2 }} pill>
                                                        {this.groups[g].name.replace(/ Support| Media/, '')}
                                                    </Tag>);
                                                })}
                                            </Col>
                                        </Row>
                                    </Grid>
                                </Well>
                            </Col>
                        );
                    })}
                </Row>
            </Grid >
        );
    }
}

export default AgentTiles;