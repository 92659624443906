export function getTicketParams(ticket) {
    if (!ticket.id && !ticket.isNew) {
        return {};
    }
    return {
        id: ticket.isNew ? 'NEW' : ticket.id,
        inPlay: ticket.postSaveAction === 'next_play_ticket',
        channel: ticket.via && ticket.via.channel ? ticket.via.channel : null,
        status: ticket.status,
        navTime: ticket.ticketNavTime || null
    };
}

export function getChatParams(chat) {
    if (!chat.id && !chat.chatNavTime) {
        return {};
    }
    return {
        id: chat.id || 'NEW',
        visitorName: chat.visitor && chat.visitor.name ? chat.visitor.name : null,
        navTime: chat.chatNavTime || null
    };
}