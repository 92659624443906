import zafClient from '../api/zafClient';

export async function getFrame(locations) {
    if (typeof locations === 'string') {
        locations = [locations];
    }
    const { instances } = await zafClient.get('instances');
    for (const instanceGuid in instances) {
        if (locations.indexOf(instances[instanceGuid].location) > -1) {
            return await zafClient.instance(instanceGuid);
        }
    }
    return;
}

export async function triggerToFrame(location, name, data) {
    try {
        const instance = await getFrame(location);
        return await instance.trigger(name, data);
    } catch (e) {
        console.error('Error on triggerToFrame', e);
    }
    return;
}

export async function invokeToFrame(location, name) {
    try {
        const instance = await getFrame(location);
        return await instance.invoke(name);
    } catch (e) {
        console.error('Error on invokeToFrame', e);
    }
    return;
}