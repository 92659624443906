import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import Loading from '../components/Loading';
import firebase from './../api/firebase';

class PrivateRoute extends Component {
  state = {
    loggedIn: false,
    loaded: false,
  }
  componentDidMount() {
    this.checkLoggedIn();
    firebase.analytics().setCurrentScreen(this.props.component.name);
    firebase.analytics().logEvent('screen_view');
  }
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }
  checkLoggedIn = () => {
    const { history } = this.props;
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      //console.log('got user', user);
      this.setState({
        loggedIn: !!user,
        loaded: true
      });
    }, error => {
      history.push('/login');
    });
  }
  render() {
    const { component: Component, ...rest } = this.props;
    const { loaded, loggedIn } = this.state;
    //if (!loaded) return null;
    return (
      <Route {...rest} render={props =>
        !loaded ?
          <Loading />
          : loggedIn ? (
            <Component {...props} />
          ) : (
              <Redirect to={{
                pathname: '/login',
                search: `?from=${encodeURIComponent(props.location.pathname)}`,
                state: { from: props.location }
              }} />
            )
      }
      />
    );
  }
}

export default withRouter(PrivateRoute);