import React, { Component } from 'react';
import zafClient from '../api/zafClient';
import firebase from '../api/firebase';
import { getChatParams } from '../utils/objectparams';
import { getFrame } from '../utils/frames';

class BackgroundChat extends Component {
    maxIdleTime = 5 * 60 * 1000; // ms
    idleTimer = null;
    isIdle = false;
    async resetIdleTimer() {
        // return to active state
        if (this.isIdle) {
            this.setStatus();
        }
        this.isIdle = false;
        clearTimeout(this.idleTimer);
        this.idleTimer = setTimeout(this.setIdle.bind(this), this.maxIdleTime);
    }
    async setIdle() {
        this.isIdle = true;
        const userObj = await zafClient.get(['currentUser.id', 'currentUser.email', 'currentUser.name']);
        await firebase.database().ref(`agents/${userObj['currentUser.id']}`).update({
            id: userObj['currentUser.id'],
            status: 'idle', // active, idle, offline, etc.
            statusTime: firebase.database.ServerValue.TIMESTAMP,
            statusAlertTime: null
        });
    }
    async setStatus() {
        const userObj = await zafClient.get(['currentUser.id', 'currentUser.email', 'currentUser.name']);
        let chat = {};
        const sidebarFrames = await getFrame('chat_sidebar');
        if (!sidebarFrames) {
            sessionStorage.removeItem('chat');
        } else if (sessionStorage.getItem('chat')) {
            chat = JSON.parse(sessionStorage.getItem('chat'));
        }
        await firebase.database().ref(`agents/${userObj['currentUser.id']}`).update({
            chat: getChatParams(chat),
            ticket: {},
            status: chat.chatNavTime ? 'in-chat' : 'ready',
            statusTime: firebase.database.ServerValue.TIMESTAMP,
            statusAlertTime: null
        });
    }
    async componentDidMount() {
        try {
            // the chat API does not allow pulling out all user obj properties with zafClient.get('currentUser')
            // returns the error: No Zendesk ID found
            const userObj = await zafClient.get(['currentUser.id', 'currentUser.email', 'currentUser.name']);
            const permission = await Notification.requestPermission();

            console.log('zendesk chat user', userObj);
            console.log('notification permission', permission);

            //const token = await messaging.getToken();
            await firebase.database().ref(`agents/${userObj['currentUser.id']}`).update({
                zendeskChatTime: firebase.database.ServerValue.TIMESTAMP,
                id: userObj['currentUser.id'],
                name: userObj['currentUser.name'],
                email: userObj['currentUser.email'],
                navTime: firebase.database.ServerValue.TIMESTAMP,
                //avatar: userObj['currentUser.avatarUrl'], // not available in chat
                // status: 'ready',
                // statusTime: firebase.database.ServerValue.TIMESTAMP
            });
            // set user offline once disconnected from database
            firebase.database().ref(`agents/${userObj['currentUser.id']}`).onDisconnect().update({
                zendeskChatTime: null,
                status: 'offline',
                statusTime: firebase.database.ServerValue.TIMESTAMP,
                statusAlertTime: null
            });

            this.resetIdleTimer();

            // update the user's state when zendesk window is hidden
            document.addEventListener('visibilitychange', async () => {
                if (document.visibilityState !== 'visible') {
                    return;
                }
                this.resetIdleTimer();
                this.setStatus();
            });
            zafClient.on('hl_user_interaction', () => this.resetIdleTimer());
            zafClient.on('hl_set_status', () => this.setStatus());

        } catch (e) {
            console.log('Error in background page', e);
        }
    }
    render() {
        // this is a background page - there is nothing to render!
        return (
            <div>
                Background Page
            </div>
        );
    }
}

export default BackgroundChat;